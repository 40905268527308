import React, { useState } from 'react';
import { InputText, InputTextProps } from '../Unform/input';
import BeatLoader from 'react-spinners/BeatLoader';
import InputGroupContainer from './InputGroupContainer';
import { FiAlertTriangle, FiCheckCircle } from 'react-icons/fi';

interface InputGroupProps extends InputTextProps {
  icon?: React.ReactNode;
  name: string;
  mask?: string;
  onValidate?: ((value: string) => string | undefined) | undefined;
  containerStyle?: string;
  externalDivStyle?: string;
  extendsInputClassName?: string;
  loading?: boolean;
  checked?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
}

export const InputTextGroup: React.FC<InputGroupProps> = ({
  name,
  icon,
  mask = '',
  onValidate,
  containerStyle = '',
  externalDivStyle = '',
  loading,
  checked,
  inputClassName = 'w-full h-full p-4 text-secundaryColor bg-white shadow-lg border border-primaryColor focus:outline-none rounded-xl',
  extendsInputClassName,
  defaultValue,
  ...rest
}) => {
  const [status, setStatus] = useState<'' | 'error' | 'success'>('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  function getStatusStyle() {
    switch (status) {
      case 'error':
        return 'border-red-500';
      case 'success':
        return 'border-green-500';
    }
  }

  return (
    <div className={`${externalDivStyle}`}>
      <InputGroupContainer className={`${containerStyle} ${getStatusStyle()}`}>
        {icon && <span className={`mr-2 `}>{icon}</span>}

        <InputText
          inputClassName={inputClassName}
          extendsInputClassName={extendsInputClassName}
          name={name}
          mask={mask}
          {...(defaultValue !== undefined && defaultValue !== null
            ? { defaultValue: new String(defaultValue).toString() }
            : {})}
          {...rest}
          setStatus={setStatus}
          onValidate={onValidate}
          setErrorMessage={setErrorMessage}
        />

        {loading && (
          <div className="relative w-16 h-4 ml-2">
            <span className="absolute">
              <BeatLoader size={12} color="#4fc3f7" />
            </span>
          </div>
        )}

        {(status === 'success' || checked) && !loading && (
          <FiCheckCircle size={24} className="ml-2 text-green-500" />
        )}
        {status === 'error' && (
          <FiAlertTriangle size={24} className="ml-2 text-yellow-500" />
        )}
      </InputGroupContainer>

      {!!errorMessage && (
        <span className="font-medium text-red-500">{errorMessage}</span>
      )}
    </div>
  );
};
