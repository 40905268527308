import styles from './InputGroup.module.css';

interface InputGroupContainerProps {
  className?: string;
  children: React.ReactNode;
}

const InputGroupContainer: React.FC<InputGroupContainerProps> = ({
  className = '',
  children,
}) => {
  return (
    <div
      className={`${styles.InputGroup} group w-full  flex items-center ${className}`}
    >
      {children}
    </div>
  );
};

export default InputGroupContainer;
