import React, { useRef, useEffect, InputHTMLAttributes } from 'react';
import ReactInputMask from 'react-input-mask';

import { useField } from '@unform/core';

export interface InputTextProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  mask?: string;
  setErrorMessage?: React.Dispatch<React.SetStateAction<string>>;
  setStatus?: React.Dispatch<React.SetStateAction<string>>;
  onValidate?: ((value: string) => string | undefined) | undefined;
  defaultValue?: string;
  inputClassName?: string;
  extendsInputClassName?: string;
}
export const InputText: React.FC<InputTextProps> = ({
  name,
  mask = '',
  setStatus,
  onValidate,
  setErrorMessage,
  defaultValue,
  inputClassName = 'w-full px-6 py-3 border border-primaryColor rounded focus:outline-none focus:ring-1 ring-primaryColor',
  extendsInputClassName = '',
  ...rest
}) => {
  const inputRef = useRef(null);

  const { fieldName, registerField, clearError, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        if (ref) {
          if (typeof ref.setInputValue === 'function') {
            return ref.setInputValue(value || '');
          }
        }
      },
      clearValue(ref: any) {
        if (ref) {
          if (typeof ref.setInputValue === 'function') {
            return ref.setInputValue('');
          }
        }
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (defaultValue && onValidate && defaultValue.length > 0) {
      handleOnValidateResult(onValidate(defaultValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  useEffect(() => {
    if (error) {
      if (setErrorMessage) setErrorMessage(error);
      if (setStatus) setStatus('error');
    }
  }, [setErrorMessage, error, setStatus]);

  function handleOnValidateResult(message?: string): void {
    if (message) {
      if (setStatus) setStatus('error');
      if (setErrorMessage) setErrorMessage(message);

      return;
    }

    if (setStatus) setStatus('success');
  }
  return mask ? (
    <ReactInputMask
      ref={inputRef}
      defaultValue={defaultValue}
      mask={mask}
      name={name}
      className={inputClassName}
      onFocus={() => {
        clearError();
        if (setErrorMessage) setErrorMessage(undefined);
        if (setStatus) setStatus('');
      }}
      onBlur={e => {
        if (onValidate && e.target.value.length > 0) {
          handleOnValidateResult(onValidate(e.target.value));
        }
      }}
      {...rest}
    />
  ) : (
    <>
      <input
        ref={inputRef}
        defaultValue={defaultValue}
        name={name}
        className={`${inputClassName} ${extendsInputClassName}`}
        onFocus={() => {
          clearError();
          if (setErrorMessage) setErrorMessage(undefined);
          if (setStatus) setStatus('');
        }}
        onBlur={e => {
          if (onValidate && e.target.value.length > 0) {
            handleOnValidateResult(onValidate(e.target.value));
          }
        }}
        {...rest}
      />
    </>
  );
};
